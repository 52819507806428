import React from "react";
import transition from "../../transition";
import {
  contactAwardListItems,
  contactJuryListItems,
  contactPublicationListItems,
} from "./contactListItems";
import mePic from "../../assets/contact/me.jpg";
import "./Contact.scss";

const Contact = () => {
  return (
    <div className="container contact__container ">
      <div className="contact__intro__first">
        <div className="contact__intro__img">
          <img src={mePic} alt="Tomi" />
          <p className="contact__intro__img__text">This is me</p>
        </div>
        <div className="contact__intro__text_container">
          <p className="contact__intro__text__title">
            <strong>Hello. I'm Tomi.</strong>
          </p>
          <p className="contact__intro__text">
            Welcome to my portfolio page. I'm glad you made it this far; it
            shows real interest!{" "}
          </p>
          <p className="contact__intro__text">
            This section is not the place for boring corporate facts. You can
            stalk those on <a href="http://linkedin.com/in/tamasadam27">LinkedIn</a>.
          </p>
        </div>
      </div>
      <div className="contact__intro__second">
        <p className="contact__intro__text">An only son after four sisters.</p>
        <p className="contact__intro__text">A country boy turned city man.</p>
        <p className="contact__intro__text">
          A hobby cook. A Wannabe Spider-Man, finger-grinding boulder and Via
          Ferrata climber.
        </p>
        <p className="contact__intro__text">
          Who wanted to be a policeman, then an archaeologist. Then a historian.
          Then an engineer. Then a history teacher. Then an economist. Then a
          marketer. Then an advertising man.
        </p>
        <p className="contact__intro__text">
          Who loves elephants and is a proud godfather of an African otter.
        </p>
        <p className="contact__intro__text">
          Whose dream is to work on a campaign for Adidas or Apple. Or both. At
          the same time, the same project. (Damn you, Nike...)
        </p>
        <p className="contact__intro__text">
          For who, it's terribly calming to wash and hang up clothes to dry.
        </p>
        <p className="contact__intro__text">
          Who has never had a more content and relaxing activity than lying in a
          hammock, gazing at the stars, while Concerning Hobbits by Howard Shore
          played softly beside him.
        </p>
        <p className="contact__intro__text">
          Who is in the top 0.01% of Alan Walker's listener base because he can
          catch the flow of his old, no-copyright music.
        </p>
        <p className="contact__intro__text">
          And who got a taste of brewing with a Belgian blondie.
        </p>
      </div>
      <div className="contact__listing__container">
        <p className="contact__contacts__title">Contact</p>
        <div className="contact__contacts__text__wrapper">
          <p className="contact__contacts__text">
            I'm a fuckin' Zoomer. Obviously, I'm always available; otherwise,
            FOMO would kick in.
          </p>
          <p className="contact__contacts__text">
            Just WhatsApp, LinkedIn, iMessage, or email me.
          </p>
          <p className="contact__contacts__text">
            My number: <strong>+36 70 490 3877</strong>{" "}
          </p>
          <p className="contact__contacts__text">
            And my email: <strong>tamas@adamiroda.hu</strong>
          </p>
        </div>
      </div>
      <div className="contact__listing__container">
        <p className="contact__contacts__title">Awards</p>
        <div className="contact__contacts__text__wrapper">
          <ul>
            {contactAwardListItems.map((item) => (
              <li key={item.id}>
                {item.titleSimple} <em>{item.titleEm}</em>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="contact__listing__container">
        <p className="contact__contacts__title">Jury Experience</p>
        <div className="contact__contacts__text__wrapper">
          <ul>
            {contactJuryListItems.map((item) => (
              <li key={item.id}>
                {item.titleSimple} <em>{item.titleEm}</em>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="contact__listing__container">
        <p className="contact__contacts__title">Other fun fact</p>
        <div className="contact__contacts__text__wrapper">
          <ul>
            <li>
              LIA’s Creative Liaisons 2022 - <em>Mentee</em>
            </li>
          </ul>
        </div>
      </div>
      <div className="contact__listing__container">
        <p className="contact__contacts__title">Publications</p>
        <div className="contact__contacts__text__wrapper">
          <ul>
            {contactPublicationListItems.map((item) => (
              <li key={item.id}>
                <a href={item.link}>{item.titleSimple} </a>
                <em>{item.titleEm}</em>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default transition(Contact);
