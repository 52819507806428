import React from "react";
import ReactPlayer from "react-player/lazy";
import "./WorkPageVideo.scss";

const WorkPageVideo = ({url}) => {
  return (
    <div className="workpage__video">
      <ReactPlayer
        url={url}
        controls
        muted={true}
        width={"100%"}
        height={"500px"}
        pip={true}
        stopOnUnmount={false}
      />
    </div>
  );
};

export default WorkPageVideo;
