const winterTale = require("../../assets/about/wintertale.png");
const oldMaid = require("../../assets/about/oldmaid.png");
const arc = require("../../assets/about/arc.png");
const colorBook = require("../../assets/about/colorbook.png");

export const aboutItems = [
  {
    id: 1,
    title: "Winter Tale — An unconventional Advent calendar",
    content:
      "We've turned the traditional Advent calendar on its head. Instead of just counting down the days with chocolates or trinkets, we've created something a little more poetic. Each day reveals a verse, carefully crafted to stand alone as a snippet of holiday cheer. But when you read them all together, they weave into a beautiful Christmas poem that's sure to warm even the chilliest of winter nights. And of course, the cherry on top is the delightful design and typography by the one and only Marcell Puskaas. Happened to be so cool that even Octogon Magazine wrote about it.",
    link: "https://www.octogon.hu/design/teli-rege-puskas-marcell-adventi-kartyacsomagja/",
    img: winterTale,
  },
  {
    id: 2,
    title: "Happy for Matching — A twisted Old Maid card deck",
    content:
      "Introducing our Valentine's Day twist on the classic Old Maid card game - because who needs traditional romance when you've got a deck of cards? Perfect for those flying solo or looking to spice up a group gathering, these cards offer a playful alternative to mushy Valentine's activities. With a themed ‘Never have I ever’ twist, get ready for romantic tales or hilarious confessions. And with design flair by Marcell Puskás, it's not just a game—it's a cheeky celebration of love and laughter. Octogon Magazine was so nice and wrote about us.",
    link: "https://www.octogon.hu/design/orulok-hogy-matcheltunk-puskas-marcell-receptje-valentin-napra/",
    img: oldMaid,
  },
  {
    id: 3,
    title: `What should I be anxious about? — @\u00AE© Exhibition`,
    content:
      "Our poster just made it to the @\u00AE© exhibition in Budapest, where we've hilariously pondered the crises of the early 2020s—in a bittersweet Hungarian style, of course. With a dash of humor and a sprinkle of irony, it's a quirky take on the chaos that defined the times. Crafted by the brilliant mind of Marcell Puskás, it's a visual rollercoaster that'll have you laughing, crying, and scratching your head in equal measure.",
    img: arc,
  },
  {
    id: 4,
    title: "The five stages of grief — Coloring book for sad singles",
    content:
      "This was our offbeat Valentine's Day offering: a coloring book for the romantically challenged, inspired by the five stages of grief. Because who says you can't laugh your way through heartache? From denial to acceptance, each page is a therapeutic journey through the trials and tribulations of singledom. A hilarious twist on the typical Valentine's fare. Led and designed by the irreverent genius, Marcell Puskás, once again.",
    link: "https://www.behance.net/gallery/191293463/Az-oet-stadium?fbclid=IwAR25t5hNzJ2r2072FNDEI2U0gQguvR6-cUaVeG3lPzATqkhWaGuKuun6lwQ",
    img: colorBook,
  },
];
