import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import "./NotImplementedPage.scss";

const NotImplementedPage = () => {
  return (
    <>
      <motion.div
        className="not-implemented-page__wrapper"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, x: -50 },
          visible: { opacity: 1, x: 0 },
        }}
      >
        <div className="not-implemented-page__text">
          <h1 className="section__title not-implemented-page__h1">Oops!</h1>
          <h2 className="section__subtitle not-implemented-page__h2">
            This page is not implemented yet. Please check back later.
          </h2>
          <Link to="/">
            {" "}
            <motion.button
              to="/"
              className="not-implemented-page__btn"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ ease: "easeOut", delay: 0.2, duration: 2 }}
            >
              Go to the main page
            </motion.button>
          </Link>
        </div>
      </motion.div>
    </>
  );
};

export default NotImplementedPage;
