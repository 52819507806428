import React from "react";
import "./Bar.css";

const Bar = ({ showNav, toggleNav }) => {
  return (
    <div id="menuToggle">
      <input type="checkbox"    checked={showNav}
        onChange={toggleNav} />
      <span id="span1"></span>
      <span id="span2"></span>
      <span id="span3"></span>
    </div>
  );
};

export default Bar;
