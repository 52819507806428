import React from "react";
// import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { workItems } from "./workItems";
import "./Work.scss";
const Work = () => {
  return (
    <div className="container work__container">
      <div className="work__tiles__container">
        {workItems.map((item) => (
          <motion.div
            key={item.id}
            className="work__item"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ duration: 0.5, delay: item.id % 2 === 0 ? 0.5 : 0 }}
            variants={{
              hidden: { opacity: 0, x: -50 },
              visible: { opacity: 1, x: 0 },
            }}
          >
            {item.title !== "Contact Me" ? (
              <a href={`/work/${item.link}`}>
                <img src={item.img} alt={item.title} />
              </a>
            ) : (
              <a href="/moretocome">
                <img src={item.img} alt={item.title} />
              </a>
            )}
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default Work;
