import React from "react";
import { Link } from "react-router-dom";
import { workItems } from "../workItems";
import "./WorkPageNavigation.scss";

const WorkPageNavigation = ({ currentLink }) => {
  const currentIndex = workItems.findIndex((item) => item.link === currentLink);
  const prevItem = currentIndex > 0 ? workItems[currentIndex - 1] : null;
  const nextItem =
    currentIndex < workItems.length - 1 ? workItems[currentIndex + 1] : null;
  // const prevLinkTitle = workItems[currentIndex - 1]?.title;
  // const prevLink = workItems[currentIndex - 1]?.link;
  // const nextLinkTitle = workItems[currentIndex + 1]?.title;
  // const nextLink = workItems[currentIndex + 1]?.link;

  return (
    <div className="workpage__navigation">
      {prevItem !== null && prevItem.link && (
        <a href={`/work/${prevItem.link}`}> {prevItem.title} &lt;</a>
      )}
      <Link to="/work">Back to Work</Link>
      {nextItem.link && nextItem.title !== "Contact Me" && (
        <a href={`/work/${nextItem.link}`}>&gt; {nextItem.title}</a>
      )}
    </div>
  );
};

export default WorkPageNavigation;
