import React from "react";
import "./Footer.scss";

const Footer = () => {


  return (
    <div className="footer">
      <div className="footer__content">
        <p className="footer__title">
          Wanna talk or work together? <br />
          Slip into my DMs:
        </p>
        <p className="footer__contacts">
          +36 70 490 3877
          <br />
          tamas@adamiroda.hu
        </p>
      </div>
    </div>
  );
};

export default Footer;
