import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import { AnimatePresence } from "framer-motion";
import "./styles/index.scss";
import "./styles/main.scss";

import Navigationbar from "./components/Navigationbar/Navigationbar";
// import Home from "./components/Home/Home";
// import About from "./components/About/About";
import Work from "./components/Work/Work";
import Contact from "./components/Contact/Contact";
import NotImplementedPage from "./components/NotImplementedPage/NotImplementedPage";
import Footer from "./components/Footer/Footer";
import BudapestSpas from "./components/Work/BudapestSpas/BudapestSpas";
import HyundaiTucson from "./components/Work/HyundaiTucson/HyundaiTucson";
import RaiffeisenBank from "./components/Work/RaiffeisenBank/RaiffeisenBank";
import Penny from "./components/Work/Penny/Penny";
import Kaiser from "./components/Work/Kaiser/Kaiser";
import EldenFakeNews from "./components/Work/EldenFakeNews/EldenFakeNews";
import Redbull from "./components/Work/Redbull/Redbull";
import HandleScrollToTop from "./hooks/HandleScrollToTop";
import About from "./components/About/About";

function App() {
  const location = useLocation();

  return (
    <>
      <HandleScrollToTop />
      <Navigationbar />
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<Work />} />
          <Route path="/after6" element={<About />} />
          <Route path="/work" element={<Work />} />
          {/* ----- WORK PAGES ----- */}
          <Route path="/work/budapestspas" element={<BudapestSpas />} />
          <Route path="/work/hyundaitucson" element={<HyundaiTucson />} />
          <Route path="/work/raiffeisenbank" element={<RaiffeisenBank />} />
          <Route path="/work/penny" element={<Penny />} />
          <Route path="/work/kaiser" element={<Kaiser />} />
          <Route path="/work/eldenfakenews" element={<EldenFakeNews />} />
          <Route path="/work/redbull" element={<Redbull />} />
          <Route path="/about" element={<Contact />} />
          <Route path="*" element={<NotImplementedPage />} />
        </Routes>
      </AnimatePresence>
      <Footer />
    </>
  );
}

export default App;
