const budapestspas = require("../../assets/work/worktiles/budapestspas.png");
const eldenfakenews = require("../../assets/work/worktiles/eldenfakenews.png");
const tucson = require("../../assets/work/worktiles/tucson.png");
const kaiser = require("../../assets/work/worktiles/kaiser.png");
const penny = require("../../assets/work/worktiles/penny.png");
const raiffeisen = require("../../assets/work/worktiles/raiffeisen.png");
const redbull = require("../../assets/work/worktiles/redbull.png");
const invite = require("../../assets/work/worktiles/invite.png");

export const workItems = [
  {
    id: 1,
    title: "Red Bull",
    link: "redbull",
    img: redbull,
  },
  {
    id: 2,
    title: "Elden Fake News",
    link: "eldenfakenews",
    img: eldenfakenews,
  },
  {
    id: 3,
    title: "Kaiser",
    link: "kaiser",
    img: kaiser,
  },
  {
    id: 4,
    title: "Penny Hungary",
    link: "penny",
    img: penny,
  },
  {
    id: 5,
    title: "Raiffeisen Bank",
    link: "raiffeisenbank",
    img: raiffeisen,
  },
  {
    id: 6,
    title: "Hyundai Tucson",
    link: "hyundaitucson",
    img: tucson,
  },
  {
    id: 7,
    title: "Budapest Spas",
    link: "budapestspas",
    img: budapestspas,
  },
  {
    id: 8,
    title: "Contact Me",
    link: "moretocome",
    img: invite,
  },
];
