import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Navigationbar.scss";
import Bar from "./Bar";

const Navigationbar = () => {
  const [showNav, setShowNav] = useState(false);

  const toggleNav = () => {
    setShowNav(!showNav);
  };

  return (
    <div className={`nav ${showNav ? "show-nav" : ""}`}>
      <div className="nav__logo">
        <Link className="nav__link" to="/work">
          Tamas Adam | Creative Copywriter
        </Link>
      </div>
      <div className="menu-icon" onClick={toggleNav}>
        <Bar showNav={showNav} toggleNav={toggleNav} />
      </div>
      <div className={`nav__links ${showNav ? "show" : ""}`}>
        {/* <div className="nav__item" >
          <Link className="nav__link" onClick={toggleNav} to="/" >
            Home
          </Link>
        </div> */}
        <div className="nav__item">
          <Link className="nav__link" onClick={toggleNav} to="/work">
            Before 6
          </Link>
        </div>
        <div className="nav__item">
          <Link className="nav__link" onClick={toggleNav} to="/after6">
            After 6
          </Link>
        </div>
        <div className="nav__item">
          <Link className="nav__link" onClick={toggleNav} to="/about">
            About
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Navigationbar;
