import React from "react";
import EldenFakeNewsPic from "../../../assets/work/eldenfakenews.png";
import "../WorkPageNavigation/WorkPageNavigation";
import "./EldenFakeNews.scss";
import WorkPageNavigation from "../WorkPageNavigation/WorkPageNavigation";
import WorkPageTitles from "../WorkPageTitles/WorkPageTitles";
import WorkPageVideo from "../WorkPageVideo/WorkPageVideo";

const EldenFakeNews = () => {
  return (
    <div className="container workpage__container">
      <WorkPageTitles
        subTitle={"Elden Fake News - Budapest Business Journal"}
        role={"Copywriter"}
        mainTitle={"Fact-check in one of the most popular game"}
      />
      <p className="work__page__p">
        Fake news poses threats to societies, economies, and businesses.
        Budapest Business Journal launched a campaign to educate future
        decision-makers on misinformation's dangers and the crucial role of
        fact-checking. Using the game 'Elden Ring' as a unique platform,
        renowned gamers fact-checked information in-game, exposing and warning
        against fake messages. Recordings of these sessions were shared on
        social media, enhancing awareness about fake news perils and promoting
        responsible media consumption.
      </p>
      <div className="workpage__img">
        <img src={EldenFakeNewsPic} alt="Elden fake news ad" />
      </div>
      <WorkPageVideo url="https://www.youtube.com/watch?v=qksyMdW22Fw&t=22s&ab_channel=CommercialJunky" />
      <WorkPageNavigation currentLink={"eldenfakenews"} />
    </div>
  );
};

export default EldenFakeNews;
