import React from "react";
import KaiserPic from "../../../assets/work/kaiser.png";
import "../WorkPageNavigation/WorkPageNavigation";
import "./Kaiser.scss";
import WorkPageNavigation from "../WorkPageNavigation/WorkPageNavigation";
import WorkPageTitles from "../WorkPageTitles/WorkPageTitles";

const Kaiser = () => {
  return (
    <div className="container workpage__container">
      <WorkPageTitles
        subTitle={"Relaunch campaign - Kaiser"}
        role={"Copywriter, Strategist, Project Lead"}
        mainTitle={`A ‘tradition of variety’ instead of ‘fancy bites’`}
      />
      <p className="work__page__p">
        Kaiser brand was one of Hungary's most popular FMCG brands, but for
        years it had not communicated through ATL or BTL channels, and its
        market share was declining in favor of well-known domestic competitors
        that advertised themselves consistently and coherently. The brand needed
        a repositioning and a relaunch, which not only included a new package
        design but also a complete overhaul of online and offline
        communications, with the elegant TVC, which put foodporn at the
        forefront, as the main backbone.{" "}
      </p>
      <p className="work__page__p">
        Thanks to the new campaign, Kaiser has re-established itself in people's
        minds, and this has been reflected in sales figures, with the brand
        recapturing a share of the market from its competitors.
      </p>
      <div className="workpage__img">
        <img src={KaiserPic} alt="Kaiser ad" />
      </div>
      <WorkPageNavigation currentLink={"kaiser"} />
    </div>
  );
};

export default Kaiser;
