import React from "react";
import BudapestSpaPic from "../../../assets/work/budapestspas.png";
import "../WorkPageNavigation/WorkPageNavigation";
import "./BudapestSpas.scss";
import WorkPageNavigation from "../WorkPageNavigation/WorkPageNavigation";
import WorkPageTitles from "../WorkPageTitles/WorkPageTitles";
import WorkPageVideo from "../WorkPageVideo/WorkPageVideo";

const BudapestSpas = () => {
  return (
    <div className="container workpage__container">
      <WorkPageTitles
        subTitle={"Sparkling - Budapest Spas and Baths"}
        role={"Junior Copywriter"}
        mainTitle={
          "One of the most successful influencer campaigns among Generation Z"
        }
      />
      <p className="work__page__p">
        Budapest is justly famous for its spas and thermal baths, but these
        not-so-hidden treasures have been most popular with the older generation
        and foreign visitors. To make it appealing to the younger generation, we
        tapped into the most-followed influencers and brought a new magic word
        into the public consciousness: sparkling.
      </p>
      <p className="work__page__p">
        The campaign then went really viral, and a new movement was started
        along with it, resulting in great numbers and a rising reputation among
        young people for the client, with a gold and a bronze for us at the 2017
        CMA and a gold at the TSMA.
      </p>
      <div className="workpage__img">
        <img src={BudapestSpaPic} alt="Budapest Spas" />
      </div>
      <WorkPageVideo url="https://www.youtube.com/watch?v=dkXQj1gSSzA&ab_channel=POMEBudapest" />
      <WorkPageNavigation currentLink={"budapestspas"} />
    </div>
  );
};

export default BudapestSpas;
