import React from "react";
import RedBullPic from "../../../assets/work/redbull.png";
import "../WorkPageNavigation/WorkPageNavigation";
import "./Redbull.scss";
import WorkPageNavigation from "../WorkPageNavigation/WorkPageNavigation";
import WorkPageTitles from "../WorkPageTitles/WorkPageTitles";

const Redbull = () => {
  return (
    <div className="container workpage__container">
      <WorkPageTitles
        subTitle={"Half Court Design - Red Bull"}
        role={"Copywriter"}
        mainTitle={"When the New York legend was reborn"}
      />
      <p className="work__page__p">
        Every year, Red Bull announces the Half Court: 3x3 basketball challenge.
        Teams of the best three players are invited to compete in exciting
        matches to decide who is the best. However, the final needs a worthy
        court, so the challenge is accompanied by a design competition: the
        winning court art will come live, and the court itself will also host
        the Half Court Challenge final. The design competition was advertised in
        print and social media.
      </p>
      <p className="work__page__p">
        The posters, which evoke the New York lifestyle, the legendary games,
        and the community spirit, follow the lines of underground culture and
        the most modern design trends of 2024, so that the print craft itself
        carries a message.
      </p>
      <div className="workpage__img">
        <img src={RedBullPic} alt="Red Bull ad" />
      </div>
      <WorkPageNavigation currentLink={"redbull"} />
    </div>
  );
};

export default Redbull;
