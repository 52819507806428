import React from "react";
import transition from "../../transition";
import { aboutItems } from "./aboutItems";
import "./About.scss";

const About = () => {
  return (
    <div className="container about__container">
      <p className="about__intro">
        What do I usually do after 6, besides watching cat videos on TikTok?
        Here I reveal the side of me that's more than just a
        sometimes-not-too-sharp pencil to the ad game. Think of it as my
        creative escape hatch from the relentless pursuit of perfect pitches and
        persuasive prose. Here, you'll find projects that are less about selling
        and more about tickling the funny bone or indulging in some good
        old-fashioned whimsy. So, kick off your advertising shoes, loosen that
        tie (or toss it altogether), and let's take a stroll through the lighter
        side of creativity.
      </p>
      <div className="about__story__container">
        {aboutItems.map((item, id) => (
          <div key={id} className="about__story__item">
            <div className="about__story__item__text">
              <h1 className="about__stroy__item__title ">{item.title}</h1>
              <p className="about__story__item__content">{item.content}</p>
              <div className="about__story__item__link">
                {item.link &&
                  (item.title ===
                  "The five stages of grief — Coloring book for sad singles" ? (
                    <a href={item.link}>You can check this out on Behance</a>
                  ) : (
                    <a href={item.link}>Here, you can check it out.</a>
                  ))}
              </div>
            </div>
            <div className="about__story__item__img workpage__img noradius">
              <img src={item.img} alt={item.title} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default transition(About);
