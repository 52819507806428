export const contactAwardListItems = [
  {
    id: 1,
    titleSimple: "ADC Global -",
    titleEm: "Merit",
  },
  {
    id: 2,
    titleSimple: "Caples -",
    titleEm: "Shortlist",
  },
  {
    id: 3,
    titleSimple: "CMA -",
    titleEm: "Gold, Bronze",
  },
  {
    id: 4,
    titleSimple: "Cresta -",
    titleEm: "Bronze, Shortlist",
  },
  {
    id: 5,
    titleSimple: "Golden Drum -",
    titleEm: "Bronze",
  },
  {
    id: 6,
    titleSimple: "MAD STARS -",
    titleEm: "Crystal",
  },
  {
    id: 7,
    titleSimple: "TSMA -",
    titleEm: "Gold",
  },
  {
    id: 8,
    titleSimple: "White Square -",
    titleEm: "Silver",
  },
];

export const contactJuryListItems = [
  {
    id: 1,
    titleSimple: "AdForum PHNX Awards -",
    titleEm: "2023",
  },
  {
    id: 2,
    titleSimple: "AdForum PHNX Awards -",
    titleEm: "2024",
  },
  {
    id: 3,
    titleSimple: "Creative Circle Awards -",
    titleEm: "2024",
  },
  {
    id: 4,
    titleSimple: "IAC Awards -",
    titleEm: "2024",
  },
];

export const contactPublicationListItems = [
  {
    id: 1,
    titleSimple: "5 Most Impactful OOH Campaigns -",
    titleEm: "LBB Online - High Five",
    link: "https://www.lbbonline.com/news/high-five-impactful-ooh-picks-that-cant-be-missed/",
  },
  {
    id: 2,
    titleSimple: "The festive mood is eaten by Venus' flytrap -",
    titleEm: "Kreativ Magazin",
    link: "https://kreativ.hu/cikk/az-unnepi-hangulatot-is-megette-venusz-legycsapoja",
  },
  {
    id: 3,
    titleSimple:
      "The AI that always answers, just sometimes not the question asked -",
    titleEm: "Kreativ Magazin",
    link: "https://kreativ.hu/cikk/chat-gpt-ai-kreativipar-adam-tamas-velemeny",
  },
  {
    id: 4,
    titleSimple: "If you don't trust the strap, why wear it? -",
    titleEm: "Kreativ Magazin",
    link: "https://kreativ.hu/cikk/ha-nem-bizol-a-szijban-miert-hordod",
  },
];
