import React from "react";
import HyundaiTucsonPic from "../../../assets/work/hyundaitucson.png";
import "../WorkPageNavigation/WorkPageNavigation";
import "./HyundaiTucson.scss";
import WorkPageNavigation from "../WorkPageNavigation/WorkPageNavigation";
import WorkPageTitles from "../WorkPageTitles/WorkPageTitles";
import WorkPageVideo from "../WorkPageVideo/WorkPageVideo";

const HyundaiTucson = () => {
  return (
    <div className="container workpage__container">
      <WorkPageTitles
        subTitle={"All-new TUCSON - Hyundai"}
        role={"Copywriter"}
        mainTitle={"One of the best-selling localization campaigns"}
      />
      <p className="work__page__p">
        The sensation of 2021 was the launch of the all-new Hyundai TUCSON. We
        had to adapt the campaign communications for the arrival of the model to
        the Hungarian market, including TVC and OOH mutations, creative
        execution of the related custom animations, and the creation of a full
        Hungarian version of the online interface.
      </p>
      <p className="work__page__p">
        Thanks to the campaign, the Hyundai TUCSON has become an extremely
        popular model in its category, and in addition to positive press
        coverage, the product launch has brought the brand numerical success.
      </p>
      <div className="workpage__img">
        <img src={HyundaiTucsonPic} alt="Budapest Spas" />
      </div>
      <WorkPageVideo url="https://www.youtube.com/watch?v=oPq0HirNFyY&ab_channel=RepublicGroup" />
      <WorkPageNavigation currentLink={"hyundaitucson"} />
    </div>
  );
};

export default HyundaiTucson;
