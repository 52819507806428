import React from "react";
import PennyPic from "../../../assets/work/penny.png";
import "../WorkPageNavigation/WorkPageNavigation";
import "./Penny.scss";
import WorkPageNavigation from "../WorkPageNavigation/WorkPageNavigation";
import WorkPageTitles from "../WorkPageTitles/WorkPageTitles";
import WorkPageVideo from "../WorkPageVideo/WorkPageVideo";

const Penny = () => {
  return (
    <div className="container workpage__container">
      <WorkPageTitles
        subTitle={"New communication platform - Penny"}
        role={"Copywriter"}
        mainTitle={"The birth of the Penny Channel"}
      />
      <p className="work__page__p">
        PENNY is one of Hungary's biggest retail brands, but neither its
        reputation nor the quality of its stores approached Western European
        standards. A completely new communication platform was needed, one that
        could make shoppers forget their old fixations and look at PENNY,
        renewed inside and out, with a completely new perspective.
      </p>
      <p className="work__page__p">
        The Penny Channel was created, showcasing all the latest news,
        innovations, and must-haves about PENNY—taking not only brand
        communication but also customer perception to a whole new level.
      </p>
      <div className="workpage__img">
        <img src={PennyPic} alt="Penny Hungary ad" />
      </div>
      <WorkPageVideo url="https://www.youtube.com/watch?v=2Oywh2ErY1c&ab_channel=PENNYMagyarorsz%C3%A1g" />
      <WorkPageNavigation currentLink={"penny"} />
    </div>
  );
};

export default Penny;
