import React from "react";
import { motion } from "framer-motion";
import "./WorkPageTitles.scss";

const WorkPageTitles = ({ subTitle, role, mainTitle }) => {
  return (
    <div className="workpage__titles__container">
      <h3
        className="work__page__h3"
      >
        {subTitle}
      </h3>
      <h4 className="work__page__h4">Role: {role}</h4>
      <motion.h1 className="work__page__h1"  initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, x: -50 },
          visible: { opacity: 1, x: 0 },
        }} >{mainTitle}</motion.h1>
    </div>
  );
};

export default WorkPageTitles;
