import React from "react";
import RaiffeisenBankPic from "../../../assets/work/raiffeisenbank.png";
import "../WorkPageNavigation/WorkPageNavigation";
import "./RaiffeisenBank.scss";
import WorkPageNavigation from "../WorkPageNavigation/WorkPageNavigation";
import WorkPageTitles from "../WorkPageTitles/WorkPageTitles";

const RaiffeisenBank = () => {
  return (
    <div className="container workpage__container">
      <WorkPageTitles
        subTitle={"Online Brandbook - Raiffeisen Bank"}
        role={"Copywriter, Project Lead"}
        mainTitle={"A guide for a renewed digital identity"}
      />
      <p className="work__page__p">
        Raiffeisen Bank was one of the largest credit institutions in Hungary,
        but its digital communication lacked consistency; there was no proper
        direction on how the brand communicated in the online space, and the
        look and feel were disjointed.
      </p>
      <p className="work__page__p">
        Thanks to the new online brandbook, Raiffeisen's digital communication
        was renewed, and the online presence became coherent, unified, and
        distinguishable, while at the same time making the work easier for the
        new designers and art directors.
      </p>
      <div className="workpage__img">
        <img src={RaiffeisenBankPic} alt="Raiffeisen Bank ad" />
      </div>
      <WorkPageNavigation currentLink={"raiffeisenbank"} />
    </div>
  );
};

export default RaiffeisenBank;
